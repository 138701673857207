import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

document.addEventListener(
    'DOMContentLoaded',
    () => {
        const rootNode = document.querySelector('body');
        const toastContainer = document.createElement('div');
        rootNode?.appendChild(toastContainer);
        if (toastContainer) {
            const rootMount = createRoot(toastContainer);
            rootMount.render(<ToastContainer position="bottom-right" />);
        }
    },
    false,
);
